import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Error = () => {
	const images = useStaticQuery(graphql`{
  errorImage: file(relativePath: {eq: "errorPage/errorImage.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
    }
  }
}
`);
	return (
        <div className="errorPage">
			<GatsbyImage
                image={images.errorImage.childImageSharp.gatsbyImageData}
                className="errorImage"
                alt="errorImage"
                loading="eager" />
		</div>
    );
};

export default Error;
